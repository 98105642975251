html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pointer {
  cursor: pointer;
}

.center-text {
  text-align: center;
}

.main-application {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loading-overlay {
  height: 100%;
}

.ptr__pull-down {
  display: none !important;
}

.page-header {
  margin-top: 10px;
}

.page-header-title {
  margin-top: 10px;
}

.pages-content {
  flex-grow: 2;
}

.footer {
  flex-shrink: 1;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.login-icon {
  margin-right: 5px;
}

.card-row,
.card-row--primary {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.09),
    rgba(255, 255, 255, 0.09)
  );
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.card-row--primary {
  background-color: teal;
}

.date-icon {
  padding-left: 20px;
}

.center {
  margin: 0 auto !important;
  display: block !important;
}

.show-results-list-row {
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
}

.show-results-list-date {
  width: fit-content;
}

.show-results-list-title {
  flex-grow: 1;
}

.show-result-name,
.show-result-horse {
  flex-grow: 2;
}

.show-result-time {
  flex-grow: 1;
}

.show-result-division-name {
  height: 24px;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
}

.rider-results-rider-list {
  margin-bottom: 20px !important;
}

.premium-star {
  color: teal;
  margin-left: 5px;
}

.show-cancelled-chip {
  font-size: 1rem !important;
  width: fit-content;
  text-align: center;
  height: fit-content !important;
  margin-top: 5px !important;
}

.user-placeholder {
  width: 35px;
}

.img {
  width: 95%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto auto;
  gap: 16px;
  justify-items: center;
  max-height: 100vh; /* Ensures no more than 3 rows */
  overflow-y: auto; /* Scroll if needed */
}

.grid-container.single-column {
  grid-template-columns: 1fr; /* Forces 1 column if there's only 1 item */
}

.grid-item {
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  align-content: center;
}

.img {
  max-width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 8px;
  transition: transform 0.2s ease-in-out;
}

.img:hover {
  transform: scale(1.05);
}

